<template>
  <div class="section-timer my-3 gap-2 px-3">
    <div class="timer-item">
      <span class="timer-num">{{ showDays }}</span>
      <span class="timer-desc">Days</span>
    </div>
    <div class="timer-item">
      <span class="timer-num">{{ showHours }}</span>
      <span class="timer-desc">Hours</span>
    </div>
    <div class="timer-item">
      <span class="timer-num">{{ showMinutes }}</span>
      <span class="timer-desc">Minutes</span>
    </div>
    <div class="timer-item">
      <span class="timer-num">{{ showSeconds }}</span>
      <span class="timer-desc">Seconds</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  props: ["year", "month", "date", "hour", "minute", "second", "milisecond"],
  data: () => ({
    showDays: 0,
    showHours: 0,
    showMinutes: 0,
    showSeconds: 0,
    showMiliseconds: 0,
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    end() {
      return new Date(
        this.year,
        this.month - 1,
        this.date,
        this.hour,
        this.minute,
        this.second,
        this.milisecond
      );
    },
  },
  mounted() {
    this.showTimer();
  },
  methods: {
    formatNum: (num) => (num < 10 ? "0" + num : num),
    showTimer() {
      const timer = setInterval(() => {
        const now = new Date();
        // const end = new Date(2021, 4, 44, 10, 10, 10, 10);
        const distance = this.end.getTime() - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          return;
        }

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        this.showMinutes = this.formatNum(minutes);
        this.showSeconds = this.formatNum(seconds);
        this.showHours = this.formatNum(hours);
        this.showDays = this.formatNum(days);
      }, 1000);
    },
  },
};
</script>
